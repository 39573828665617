.contact-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2rem;
    min-height: 100vh;
  }
  
  .contact-page h1,
  .contact-page h2,
  .contact-page h3 {
    text-align: center;
    font-family: var(--secondary-font);
  }
  
  .contact-page h1 {
    font-size: var(--font-size-h1);
    color: var(--dark-navy);
  }
  
  .contact-page h2 {
    font-size: var(--font-size-h2);
    color: var(--steel-gray);
  }
  
  .contact-page h3 {
    font-size: var(--font-size-h3);
    color: var(--deep-silver);
  }
  
  .contact-icons {
    display: flex;
    justify-content: center;
    gap: 2rem;
    margin-bottom: 2rem;
  }
  
  .contact-icons a {
    display: inline-block;
  }
  
  .contact-icons svg {
    transition: color 0.3s ease;
  }
  
  .contact-icons a:hover svg {
    color: var(--neon-blue);
  }
  
  #contact-form {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 600px;
    background-color: var(--steel-gray);
    padding: 2rem;
    border-radius: 8px;
  }
  
  #contact-form input,
  #contact-form textarea {
    font-family: var(--primary-font);
    padding: 1rem;
    margin: 1rem 0;
    border: 1px solid var(--deep-silver);
    border-radius: 4px;
    background-color: var(--white);
    color: var(--dark-navy);
  }
  
  #contact-form input:focus,
  #contact-form textarea:focus {
    border-color: var(--neon-blue);
    outline: none;
  }
  
  #contact-form button.submit-btn {
    padding: 1rem;
    background-color: var(--neon-blue);
    color: var(--white);
    border: none;
    border-radius: 4px;
    font-size: var(--font-size-h4);
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  #contact-form button.submit-btn:hover {
    background-color: var(--electric-cyan);
  }
  
  #contact-form button.submit-btn:disabled {
    background-color: var(--deep-silver);
    cursor: not-allowed;
  }
  