.news-instragram-container {
    width: 100%;
    height: 100vh; /* Make it take up the full screen height */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .news-instragram-container iframe {
    width: 100%;
    height: 100vh; /* Ensure iframe also takes full height */
    border: none;
    overflow: hidden;
  }
  
  .events-container{
    text-align: center;
  }

  .events-container h1{
    font-size: var(--font-size-h1);
    font-family: var(--primary-font); /* Use primary font family */
    font-weight: 300; /* Lighter font weight */
    letter-spacing: 0.5px; /* Slight spacing between letters */
    margin: 0;
  }

  .events-container h2{
    font-size: var(--font-size-h2);
    font-family: var(--primary-font); /* Use primary font family */
    font-weight: 300; /* Lighter font weight */
    letter-spacing: 0.5px; /* Slight spacing between letters */
    margin: 0;
  }

  .event-socials-container{
    background-color: white; /* White background for a clean look */
    border: 5px solid var(--secondary-dark-navy); /* Border color using the custom variable */
    border-radius: 12px; /* Rounded corners */
    padding: 10px; /* Padding inside the container */
    width: 80%; /* Takes up most of the parent width */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for a sleek look */

    display: flex;
    flex-direction: column;
    align-items: center;
    
    margin: 5%; /* Adds space around it while centering horizontally */
  }