.sponsor-section {
  width: 100%;
  background-color: var(--white); /* Keeps the background white */
  display: flex;
  justify-content: center;
  align-items: stretch; /* Ensure equal height */
  padding: 3rem 0;
}

.Sponsors h1 {
  font-family: var(--secondary-font);
  font-size: var(--font-size-h1);
  text-align: center;
}

.sponsor-section-container {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch; /* Ensures equal height */
  width: 100%;
  padding-left: 3rem;  /* Adds left margin to prevent touching the edge */
  padding-right: 3rem; /* Adds right margin to prevent touching the edge */
  padding-top: 3rem;
}

.sponsor-image-container,
.sponsor-text-container {
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
  min-height: 100%;
}

.sponsor-image-container {
  width: 100%;
  height: auto; /* Maintains the aspect ratio of the images */
}

.sponsor-image-container img {
  width: 80%; /* Ensures the image fills its container's width */
  height: auto; /* Maintains the aspect ratio */
  object-fit: contain; /* Ensures the image fits within its container without stretching */
  object-position: center; /* Centers the image */
}

.sponsor-text-container {
  text-align: center;
  padding: 2rem;
  font-weight: bold; /* Makes the text bold */
}

.sponsor-text-container h2 {
  font-family: var(--primary-font);
  font-size: var(--font-size-h2);
  font-weight: 700; /* Bold text */
  text-transform: uppercase;
}

.sponsor-text-container h5 {
  font-family: var(--secondary-font);
  font-size: var(--font-size-h4);
  font-weight: 500;
  margin-left: 5%;
  margin-right: 5%;
}

.sponsors-container {
  display: flex;
  flex-direction: column; /* Stack the content vertically */
  justify-content: center; /* Center content vertically */
  align-items: center; /* Center content horizontally */
  text-align: center; /* Optional: center the text within the container */
  padding: 20px; /* Optional: add some padding */
}

.sponsorship-team {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
}

.sponsorship-team h2{
  font-family: var(--primary-font);
  font-size: var(--font-size-h2);
}

.sponsorship-team h3{
  font-family: var(--secondary-font);
  font-size: var(--font-size-h4);
  margin: 0;
}

.leap-container img{
  height: 70%;
}

/* Centering the video */
.video-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5%;
}

/* Wrapper to control width */
.video-wrapper {
  width: 75%;
}

/* Ensuring responsiveness */
.video-frame {
  width: 100%;
  aspect-ratio: 16 / 9; /* Maintains aspect ratio */
}
