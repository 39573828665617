.robots101-top-section{
    display: flex;
    align-items: center;
}




/* Wrapper centers the grid in the middle of the screen */

.robotics101-grid-titls{
    text-align: center;
    font-size: var(--font-size-h1);
    font-family: var(--primary-font);
}

.robotics101-grid-wrapper {
    display: flex;
    align-content: center;
    justify-content: center;
    width: 90%
  }

  .robotics101-grid-container{
    display: flex;
    align-content: center;
    justify-content: center;
    width: 100%; /* Full width of the wrapper */
  }
  
  
  /* Ensures each grid item is a perfect square */
  .robotics101-grid-item {
    width: 95%; /* Grid system handles column width */
    aspect-ratio: 1 / 1; /* Keeps each item a square */
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  /* Alternating colors */
  .robotics101-alt {
    background-color: var(--steel-gray);
    color: white;
    border: 2.5px solid var(--secondary-dark-navy);
    width: 90%;
    aspect-ratio: 1 / 1; 
    display: flex;
    justify-content: center; /* Horizontally centers the content */
    align-items: center; /* Vertically centers the content */
  }

  .robotics101-alt h3{
    text-align: center;
    font-size: var(--font-size-h3);
    font-family: var(--primary-font);
  }

  .robotics101-alt p{
    text-align: center;
    font-size: var(--font-size-h4);
    font-family: var(--secondary-font);
    margin: 5%;
  }

  .robotics101-alt li{
    text-align: left;
    font-size: var(--font-size-h4);
    font-family: var(--secondary-font);
    margin: 2%;
  }


  .robotics101-white {
    background-color: white;
    color: black;
    border: 5px solid var(--secondary-dark-navy);
    width: 90%;
    aspect-ratio: 1 / 1; 
    display: flex;
    justify-content: center; /* Horizontally centers the content */
    align-items: center; /* Vertically centers the content */
    
  }

  .robotics101-white h3{
    text-align: center;
    font-size: var(--font-size-h3);
    font-family: var(--primary-font);
  }

  .robotics101-white p{
    text-align: center;
    font-size: var(--font-size-h4);
    font-family: var(--secondary-font);
    margin: 5%;
  }

  .robotics101-white li{
    text-align: left;
    font-size: var(--font-size-h4);
    font-family: var(--secondary-font);
    margin: 2%;
  }

  .robots101-project-headshot {
    display: flex;
    flex-direction: column; /* Stack image and text vertically */
    justify-content: center; /* Center content vertically */
    align-items: center; /* Center content horizontally */
    text-align: center; /* Ensure text is centered */
    height: 100%;
}

  .robots101-headshot-image {
    border-radius: 50%; 
    margin: 0;
    padding: 0;
    object-fit: cover;
    width: 100%;
    height: 100%;
    aspect-ratio: 1/1;
  }

  .robots101-headshot-image-border{
    background-color: #112236;
    border-radius: 50%;
    display: flex;
    padding: 2%;
    width: 65%;
    aspect-ratio: 1/1;
  }

  .robots101-project-headshot h2{
    margin: 0;
    text-align: center;
    font-size: var(--font-size-h2);
    font-family: var(--primary-font);
  }

  .robots101-project-headshot h5{
    margin: 0;
    text-align: center;
    font-size: var(--font-size-h5);
    font-family: var(--secondary-font);
  }

  .robotics101-image {
    width: 90%;
    aspect-ratio: 1 / 1; 
  }

  .robotics101-image img{
    width: 100%;
    aspect-ratio: 1 / 1;
    border: 5px solid var(--secondary-dark-navy);
  }

  .robots101-button{
    
        font-family: var(--primary-font);          /* Use primary font */
        font-size: var(--font-size-h4);                            /* Base font size */
        font-weight: 600;                           /* Semi-bold for impact */
        letter-spacing: 0.5px;                       /* Slight letter spacing */
        text-transform: uppercase;                  /* Uppercase text for emphasis */
        color: var(--dark-navy);                    /* Inverted: dark navy text */
        background-color: var(--white);             /* Inverted: white background */
        border: none;                               /* Remove default border */
        width: 50%;                                 /* 50% of the container width */
        height: 60px;                               /* Fixed height */
        border-radius: 30px;                        /* Pill shape */
        cursor: pointer;                            /* Pointer cursor on hover */
        transition: background-color 0.3s ease, transform 0.2s ease, filter 0.3s ease;
        margin-top: 20px;                           /* Space above the button */
        
        /* Center text horizontally and vertically */
        display: flex;
        align-items: center;
        justify-content: center;
  }
  
  .robots101-button:hover{
    filter: brightness(0.9);                    /* Darker on hover */
    transform: translateY(-2px);                /* Slight lift effect on hover */
  }

  .robots101-button:active {
    transform: translateY(0);                   /* Reset transform on click */
  }

  .robotics101-link {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }