.drc-banner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 40px 20px;
  background-color: var(--steel-gray);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
  position: relative;
  z-index: -1; /* Ensure content stays above the trapezoid */
}

/* Full-width downward trapezoid */
.drc-banner::after {
  content: "";
  position: absolute;
  bottom: -20%;
  left: 0;
  width: 100vw;
  height: 20vh;
  background-color: var(--steel-gray);
  clip-path: polygon(0% 0%, 100% 0%, 85% 100%, 15% 100%);
}

.title {
  font-family: var(--secondary-font);
  font-size: var(--font-size-h1);
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 3px;
  color: white; /* Ensures text is visible */
  background: white;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; /* Allows gradient text effect */
  position: relative;
  z-index: 1; /* Ensures text is above the trapezoid */
  animation: slideIn 1s ease-out;
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateX(-100px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}



.drc-upper-text {
  display: flex;
  justify-content: center; /* Centers content horizontally */
  align-items: center; /* Vertically center content */
  width: 60%; /* Takes up 60% of the screen width on larger screens (e.g., laptops) */
  margin-top: 2.5%; /* Space between the trapezoid and the text */
  margin-left: auto;
  margin-right: auto; /* Centers the element */
  color: black; /* White text for visibility */
  font-family: var(--primary-font);
  font-size: var(--font-size-h2);
  letter-spacing: 0.5px;
  text-align: center; /* Centers the text */
}

/* Media query for small screens (e.g., mobile devices) */
@media (max-width: 768px) {
  .drc-upper-text {
    width: 90%; /* On smaller screens, take up 90% of the screen width */
    margin-top: 5%;
  }
}




.drc-trapezoid {
  width: 100%;
  height: 50px; /* Adjust height as needed */
  background-color: var(--steel-gray); /* Match text-container background */
  clip-path: polygon(0% 100%, 100% 100%, 85% 0%, 15% 0%); /* Creates the trapezoid shape */
}






.drc-text-container {
  display: flex;
  flex-direction: column; /* Stack the text vertically */
  justify-content: center; /* Center content vertically */
  align-items: center; /* Center content horizontally */
  background-color: var(--steel-gray);
  color: var(--white);
  padding: 2rem;
  line-height: 1.8;
  text-align: center; /* Center-align text horizontally */
  border-radius: 0px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  width: 100%;
  height: auto;
  position: relative;
  z-index: 2;
}

.drc-text-container h2,
.drc-text-container p {
  width: 90%; /* Ensure both elements take the full width of the container */
  margin: 0; /* Remove default margin to ensure proper alignment */
}

.drc-text-container p {
  font-size: var(--font-size-h4);
  font-family: var(--secondary-font);
  font-weight: 300;

  text-align: center; /* Center-align text horizontally */
  margin: 2.5rem;

}

.drc-text-container h2 {
  font-size: var(--font-size-h2);
  font-family: var(--primary-font);
  font-weight: 300;
  text-align: center;
  margin-left: 2.5rem;
  margin-bottom: 1rem;
  margin-top: 1rem;;
}


@media (max-width: 768px) {
  .drc-about-text-section {
    height: auto; /* Let it expand naturally */
    min-height: 50vh;
  }
}




.drc-EOI-wrapper {
  width: 100%; /* Default width */
  
  /* Centering */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  
  /* Responsive design */
  @media (max-width: 768px) {
      width: 80%; /* Smaller screens take 80% width */
  }
}




.drc-results-wrapper h1{
  text-align: center;
  font-size: var(--font-size-h1);
  font-family: var(--primary-font);
  font-weight: 300;
  margin-top: 2.5%;
  margin-bottom: 2.5%;
}


.drc-results-container{
  height: 100%; /* Both sections take equal height */
  display: flex;
  flex-direction: column; /* Stack children vertically */
  align-items: center; /* Align children to the start of the container */
  justify-content: center; /* Align children at the top */
}

.drc-results-container img {
  width: 80%;
  height: 100%;
  object-fit: cover; /* Ensure image covers the container without distortion */
  padding: 0;
  margin: 0; /* Ensure there's no default margin */
  display: block; /* Remove any inline display issues */
}

.drc-results-wrapper{
  margin-bottom: 2.5%;
}



.drc-gallery-container img {
  transition: transform 0.3s ease-in-out;
}

.drc-gallery-container img:hover {
  transform: scale(1.1);
}






.DRC-faq-container {
  color: var(--white);
  padding: 2rem;
  width: 80%;
  margin: 2rem auto;
  font-family: var(--primary-font);
}

.DRC-faq-title {
  text-align: center;
  font-size: var(--font-size-h1);
  font-family: var(--secondary-font);
  color: black;
  margin-bottom: 1.5rem;
}

.DRC-faq-item {
  background-color: var(--steel-gray);
  border-radius: 8px;
  padding: 1rem;
  margin-bottom: 1rem;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.DRC-faq-item:hover {
  background-color: var(--secondary-dark-navy);
}

.DRC-faq-question {
  font-size: var(--font-size-h3);
  font-weight: bold;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.DRC-faq-answer {
  font-size: var(--font-size-h4);
  line-height: 1.6;
  color: var(--deep-silver);
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-in-out, opacity 0.3s ease-in-out;
  opacity: 0;
  padding-top: 0;
}

.DRC-faq-item.open .DRC-faq-answer {
  max-height: 500px;
  opacity: 1;
  padding-top: 0.5rem;
}

.drc-link {
  font-family: var(--primary-font);          /* Use primary font */
  font-size: var(--font-size-h4);            /* Base font size */
  font-weight: 600;                          /* Semi-bold for impact */
  letter-spacing: 0.5px;                     /* Slight letter spacing */
  text-transform: uppercase;                 /* Uppercase text for emphasis */
  color: var(--white);                       /* Inverted: dark navy text */
  background-color: var(--steel-gray);       /* Inverted: white background */
  border: none;                              /* Remove default border */
  width: 50%;                                /* 50% of the container width */
  height: 60px;                              /* Fixed height */
  border-radius: 30px;                       /* Pill shape */
  cursor: pointer;                           /* Pointer cursor on hover */
  transition: background-color 0.3s ease, transform 0.2s ease, filter 0.3s ease;
  margin-top: 20px;                          /* Space above the button */
  
  /* Center text horizontally and vertically */
  display: flex;
  align-items: center;
  justify-content: center;
}

.drc-link:hover {
  filter: brightness(1.5);                   /* Darker on hover */
  transform: translateY(-2px);               /* Slight lift effect on hover */
}

.drc-link:active {
  transform: translateY(0);                  /* Reset transform on click */
}