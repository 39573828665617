.roar_image-container {
    position: relative;
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1%;
  }
  
  .roar_image-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Dark overlay for contrast */
    border-bottom: 5px solid var(--secondary-dark-navy); /* Added navy blue border */
  }
  
  .roar_overlay-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    text-align: center;
    z-index: 2;
    width: 90%; /* Ensure text is readable and not cut off */
    padding: 10px;
  }
  
  .roar_overlay-text h1 {
    font-size: clamp(2rem, 5vw, 5rem); /* Scales based on viewport width */
    font-family: var(--primary-font);

    letter-spacing: 2px;
    margin: 0;
  }
  
  .roar_overlay-text h3 {
    font-size: clamp(1rem, 3vw, var(--font-size-h2)); /* Adjusts dynamically */
    font-family: var(--secondary-font);
    margin-top: 5px;
  }
  
  /* Mobile Adjustments */
  @media (max-width: 768px) {
    .roar_overlay-text {
      width: 95%; /* Increase width slightly for better text alignment */
      padding: 5px;
    }
  
    .roar_overlay-text h1 {
      font-size: 3rem; /* Reduce font size for smaller screens */
    }
  
    .roar_overlay-text h3 {
      font-size: 1.2rem;
    }
  }
  


  .roar-intro-section {
    display: flex;
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
  }
  
  .roar-grid {
    display: flex;
    justify-content: center;

  }
  

  .roar-intro-section p {
    font-size: var(--font-size-h3); /* Adjusts dynamically */
    font-family: var(--primary-font);
    text-align: center; /* Horizontally center the text */
    display: flex;
    justify-content: center; /* Aligns text horizontally */
    align-items: center; /* Aligns text vertically */
    height: 100%; /* Ensures the text takes up the full height of the container */
    margin-left: 5%;
    margin-right: 5%;
}

.roar-lead{
    margin-top: 5%;
    margin-bottom: 5%;
}



.roar-achievments {
    background-color: var(--steel-gray); /* Set background to steel gray */
    text-align: center; /* Center the content */
    display: flex;
    justify-content: center; /* Horizontally center the content */
    align-items: center; /* Vertically center the content */
    flex-direction: column;
    margin-top: 2.5%;
    padding-bottom: 5%;
  }
  
  .roar-achievments h1 {
    font-size: var(--font-size-h1);
    font-family: var(--secondary-font);
    color: var(--white);
    margin-bottom: 3rem; /* Space below the title */
  }

  .roar-achievment-container {
    display: flex;
    justify-content: center;  /* Center the content horizontally */
    align-items: center;      /* Center the content vertically */
    width: 100%;              /* Take up the full width of the grid */
    height: 100%;             /* Make the container take full height of its parent */
  }
  
  .roar-achievment {
    background-color: var(--secondary-dark-navy); /* Using secondary background color for cards */
    border: 2px solid var(--neon-blue); /* Stylish neon blue border for the card */
    padding: 2rem;
    margin: 1rem;

    border-radius: 12px; /* Rounded card corners */
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1); /* Soft shadow for depth */
    transition: transform 0.3s ease, box-shadow 0.3s ease, background-color 0.3s ease; /* Smooth hover effects */
    width: 90%; /* Limit width */
    max-width: 500px; /* Max width of the card */
  }
  
  .roar-achievment:hover {
    transform: translateY(-8px); /* Lift effect on hover */
    box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.3); /* Stronger shadow on hover */
    background-color: var(--neon-blue); /* Glowing effect: change background color on hover */
  }
  
  .roar-achievment h2 {
    font-size: var(--font-size-h3); /* Larger heading */
    font-family: var(--secondary-font);
    color: var(--white); /* White text for emphasis */
    font-weight: bold; /* Standout text */
    margin: 1rem 0; /* Space between headings */
  }
  
  .roar-achievment h3 {
    font-size: var(--font-size-h5); /* Smaller text for subheadings */
    font-family: var(--primary-font);
    color: var(--deep-silver);
    margin: 0.5rem 0; /* Small space for clarity */
  }
  
  @media (max-width: 768px) {
    .roar-achievments {
      padding: 3rem 1rem; /* Adjust padding on smaller screens */
    }
  
    .roar-achievment {
      margin: 1.5rem 0; /* Adjust margin for mobile */
      padding: 1.5rem; /* Adjust padding for smaller viewports */
    }
  
    .roar-achievment h2 {
      font-size: var(--font-size-h4); /* Smaller font size for mobile */
    }
  
    .roar-achievment h3 {
      font-size: var(--font-size-h6); /* Smaller text for subheadings */
    }
  }
  
  .roar-achievment-trophy {
    max-width: 80%;
    height: auto; /* Maintain aspect ratio */
    display: block;
    margin: 0 auto; /* Center image horizontally */
  }

  @media (max-width: 768px) {
    .roar-achievment-trophy {
      width: 50%;  /* Scale down image on smaller screens */
    }
  }


  .roar-gallery-container h1{
    font-family: var(--primary-font);
    font-size: var(--font-size-h1);
    text-align: center;
    margin-top: 2.5%;
  }

  .roar-gallery-container{
    margin-bottom: 2.5%;
  }



.roar-container{
    background-color: var(--steel-gray);
}


  .roar-image-container {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .roar-image-wrapper {
    position: relative;
    width: 80%;  /* Set the wrapper to 80% of the container width */
    height: 80%; /* Optionally, set a height to control the aspect ratio */
  }
  
  .roar-image {
    width: 100%;  /* Image takes up the full width of the wrapper (80% of container width) */
    height: auto;  /* Adjust height to maintain aspect ratio */
    object-fit: cover;  /* Ensure image is cropped if necessary while maintaining aspect ratio */
    border-radius: 10px;
  }
  
  .roar-title {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;  /* White text for contrast */
    font-family: var(--primary-font);
    font-size: var(--font-size-h2);
    font-weight: bold;
    text-align: center;
    text-shadow: 3px 3px 8px rgba(0, 0, 0, 0.6); /* Stronger text shadow */
    padding: 10px;
    background: rgba(0, 0, 0, 0.5);  /* Semi-transparent black background */
    border-radius: 10px;
  }
  

  .roar-text-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .roar-contact-container {
    background-color: white; /* White background for a clean look */
    border: 5px solid var(--secondary-dark-navy); /* Border color using the custom variable */
    padding: 20px; /* Padding inside the container */
    width: 90%; /* Takes up most of the parent width */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for a sleek look */

    display: flex;
    flex-direction: column;
    align-items: center;
    
    margin: 5%; /* Adds space around it while centering horizontally */
  
}



  .roar-contact-container h1{
    font-family: var(--primary-font);
    font-size: var(--font-size-h2);
    text-align: center;
    margin: 0%;
  }

  .roar-contact-container h3{
    font-family: var(--secondary-font);
    font-size: var(--font-size-h4);
    margin-right: 1%;
  }

  .roar-link{
    
    font-family: var(--primary-font);          /* Use primary font */
    font-size: var(--font-size-h4);                            /* Base font size */
    font-weight: 600;                           /* Semi-bold for impact */
    letter-spacing: 0.5px;                       /* Slight letter spacing */
    text-transform: uppercase;                  /* Uppercase text for emphasis */
    color: var(--white);                    /* Inverted: dark navy text */
    background-color: var(--steel-gray);             /* Inverted: white background */
    border: none;                               /* Remove default border */
    width: 50%;                                 /* 50% of the container width */
    height: 60px;                               /* Fixed height */
    border-radius: 30px;                        /* Pill shape */
    cursor: pointer;                            /* Pointer cursor on hover */
    transition: background-color 0.3s ease, transform 0.2s ease, filter 0.3s ease;
    margin-top: 20px;                           /* Space above the button */
    
    /* Center text horizontally and vertically */
    display: flex;
    align-items: center;
    justify-content: center;
}

.roar-link:hover{
filter: brightness(1.5);                    /* Darker on hover */
transform: translateY(-2px);                /* Slight lift effect on hover */
}

.roar-link:active {
transform: translateY(0);                   /* Reset transform on click */
}

.robotics101-link {
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
text-align: center;
}
  
 