footer {
    background-color: rgb(15, 22, 39);
    color: white;
    display: flex;
    padding: 0.8rem 0rem;
    bottom: 0;
    left: 0;
    height: 40px;
    width: 100%;
    position: fixed;
    align-items: center;

    z-index: 1000;
}

.footer-logo{
    list-style-type: none;
  
  position: absolute;

  margin-top: 0.3rem;
  width: 4rem;
  top: 0;
  left: 0;
  z-index: 5;
}

.normal {
  filter:invert(0%)
}
.inverted {
  filter:invert(100%)
}

.footer-socials{
    padding: 0;
    margin-top: 1rem;
    margin-left: auto;
    display: flex;
  }

.footer-socials a {
    height: 50px;
    display: block;
}

  .footer-socials li {
    list-style-type: none;
    text-align: center;
    margin-right: 0.6rem;
  }
  
  .footer-text {
    font-size: smaller;
  }

  .footer-button {
      text-decoration: none;
      background-color: white;
      color: rgb(15, 22, 39);
      line-height: 50px;
      padding: 0 12px;
      border-radius: 0.4rem;
      border: none;
      font-family: 'Roboto', sans-serif;
      font-size: 27px;
      font-weight: bold;
  }

#footer-links {
    display: flex;
    justify-content: space-evenly;
    flex-grow: 1;
    width: 50%;
}

@media screen and (max-width: 718px) {
    #footer-links {
        display: none;
    }
}

@media screen and (max-width: 320px) {
  .footer-socials {
    display: none;
  }
}