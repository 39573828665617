.navbar {
    --nav-height: 80px;

    background-color: rgb(15, 22, 39);
    width: 100%;
    display: flex;
    align-items: center;
    position: relative;
    color: white;
    box-shadow: 0 2px 2px 2px rgba(9, 9, 9, 0.23);
    min-height: var(--nav-height);
    height: var(--nav-height);
}

.brand-name {
    text-decoration: none;
    color: white;
    font-size: medium;
    text-align: left;
}

.brand-icon {
    height: 50px;
    width: 50px;
    padding: 0 0.5rem;
}

.nav-tabs {
    margin-left: auto;
    display: flex;
    text-align: center;
    justify-content: space-evenly;
    height: 100%;
    width: 80%;
}




.nav-tab {
    display: block;
    color: white;
    width: 100%;
    flex: 1;
    height: var(--nav-height);
    text-decoration: none;
}

.nav-title {
    height: 100%;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

.nav-tab span {
    display: contents;
}

.nav-tab:hover {
    background-color: #2c3a57;
    cursor: pointer;
}

/* Drop down */
.dropdown-content {
    display: none;
    position: absolute;
    z-index: 2;
    background-color: #ffffff;
    width: auto;
    max-height: 300px;
    overflow-y: auto;
    overflow-x: hidden;
    line-height: var(--nav-height);    
}

.dropdown:hover .dropdown-content {
    display: block
}


/* .dropdown-content a to have width of the largest element */
.dropdown-content a {
    background-color: #0f1627;
    display: block;
    width: 100%;
    text-align: center;
    overflow-x: anywhere;
    text-decoration: none;
    color: white;
    float: left;
}


.dropdown-content a:hover {
    background-color: #2c3a57;
}

/* Hamburger menu */
.hamburger {
    display: none;
    border: 0;
    height: 40px;
    width: 40px;
    padding: 0.5rem;
    border-radius: 50%;
    background-color: #283b8b;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
    position: absolute;
    top: 50%;
    right: 25px;
    transform: translateY(-50%);
}

.hamburger:hover {
    background-color: #2642af;
}

@media screen and (max-width: 800px) {
    .hamburger {
        display: block;
    }

    .dropdown-content, .dropdown-indicator {
        display: none !important;
    }

    .nav-tabs {
        display: none;
        position: absolute;
        top: var(--nav-height);
        left: 0;
        flex-direction: column;
        width: 100%;
        background-color: rgb(15, 22, 39);
        border-top: 1px solid black;
        z-index: 500;
        box-shadow: 0 2px 2px 2px rgba(9, 9, 9, 0.23);
    }

    .nav-tabs.expanded {
        display: block;
    }

    .nav-tab {
        display: block;
        text-align: center;
        margin: 0;
        border-top: 1px solid rgba(80, 72, 124, 0.167);
        height: 45px;
        background-color: rgb(15, 22, 39);
    }

    .nav-tab a {
        line-height: 45px;
    }


    .nav-tab:hover {
        transform: scale(1);
        background-color: rgb(130, 143, 186);
    }
}