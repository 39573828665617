  .MuiGrid-item {
    padding: 0 !important; /* Remove padding */
  }










.about-text-Section {
    width: 100%;
    height: 50vh; /* Keep it at 50% of viewport height */
    min-height: 300px; /* Prevent it from becoming too small */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; /* Center content vertically */
    overflow: hidden; /* Prevent overflowing content */
  }
  
  .section-container {
    height: 100%; /* Ensure the grid takes full height of the parent */
  }
  
  .image-container,
  .text-container {
    height: 100%; /* Both sections take equal height */
    display: flex;
    flex-direction: column; /* Stack children vertically */
    align-items: flex-start; /* Align children to the start of the container */
    justify-content: flex-start; /* Align children at the top */
  }
  
  .image-container img {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensure image covers the container without distortion */
    padding: 0;
    margin: 0; /* Ensure there's no default margin */
    display: block; /* Remove any inline display issues */
  }
  
  .text-container {
    display: flex;


    background-color: var(--steel-gray); /* Secondary primary color */
    color: var(--white); /* Ensure text is white */
    padding: 2rem; /* Add padding around the text */
    line-height: 2; /* Improve readability with line height */
    text-align: left; /* Align text to the left */
    border-radius: 0px; /* Rounded corners for the text section */
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* Soft shadow for depth */
    width: 100%; /* Ensure it takes full width */
    height: auto;
  }
  
  .text-container h2,
  .text-container p {
    width: 90%; /* Make sure both h2 and p take full width */
    margin-left: 5%;
  }
  
  .text-container p {
    font-size: var(--font-size-h5); /* Adjust text size with variable */
    font-family: var(--secondary-font); /* Use primary font family */
    font-weight: 300; /* Lighter font weight */
    letter-spacing: 0.5px; /* Slight spacing between letters */
  }

  .text-container h2 {
    margin-top: 5%;
    text-align: center;
    font-size: var(--font-size-h2); /* Adjust text size with variable */
    font-family: var(--primary-font); /* Use primary font family */
    font-weight: 300; /* Lighter font weight */
    letter-spacing: 0.5px; /* Slight spacing between letters */
  }

  @media (max-width: 768px) {
    .about-text-Section {
      height: auto; /* Let it expand naturally */
      min-height: 50vh; /* Still try to keep a minimum height */
    }
  }
  




  .about-values {
    display: flex;
    flex-direction: column;
    align-items: center; /* Centers content horizontally */
    justify-content: center; /* Centers content vertically */
    text-align: center;
    position: relative; /* Keeps it in normal document flow */
    z-index: 1;
    width: 100%;
    max-width: 75%;
    margin: 0 auto; /* Ensures horizontal centering */
    margin-bottom: 5%;
  }
  
  .about-values h2 {
    font-size: var(--font-size-h1);
    font-family: var(--primary-font);
    margin-bottom: 1rem;
  }
  
  .about-values img {
    width: 100%;
    max-width: 80%; /* On laptop, image takes up 80% of .about-values */
    height: auto;
  }
  
  /* 📱 Adjust for Mobile */
  @media (max-width: 768px) {
    .about-values {
      max-width: 100%; /* Full width on smaller screens */
    }
  
    .about-values img {
      max-width: 100%; /* Image also takes full width on mobile */
    }
  }
  
  



  .about-projects {
    width: 80%;
    margin: 0 auto;
    margin-bottom: 3%;
    text-align: center;
    color: var(--white);
}

.about-projects h2 {
    font-family: var(--secondary-font);
    font-size: var(--font-size-h1);
    color: black;
    flex-wrap: wrap;
    gap: 10px;  /* Controls horizontal spacing */
}

.about-projects-container {
    display: flex;
    justify-content: center;
}

.about-project-link {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
}

.project-button {
  background-color: var(--steel-gray);
  padding: 2rem;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
  width: 100%;
  max-width: 280px;  /* Set same width for all */
  height: 320px;  /* Ensure all buttons are the same height */
  text-align: center;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px; /* Controls vertical spacing */
}

.project-button:hover {
    background-color: var(--electric-cyan);
    transform: translateY(-5px);
}

.project-button img {
    width: 100%;
    height: auto;
    border-radius: 8px;
    margin-bottom: 1rem;
}

.project-button h3 {
    font-family: var(--secondary-font);
    font-size: var(--font-size-h3);
    color: var(--white);
    margin-top: 0;
}

@media (max-width: 768px) {
    .about-projects-container {
        flex-direction: column;
        align-items: center;
    }

    .project-button {
        max-width: 100%;
    }

    .project-button h3 {
        font-size: var(--font-size-h4);
    }
}






.about-sponsors {
  width: 80%;
  margin: 0 auto;
  text-align: center;
  padding: 4rem 0;
}

.about-sponsors h2 {
  font-family: var(--secondary-font);
  font-size: var(--font-size-h1);
  color: black;
  margin: 0;
  margin-bottom: 2rem;
}

.about-sponsor-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.about-sponsor {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-bottom: 50%;
}

.about-sponsor img {
  width: 100%; /* Ensures images fit their grid section */
  max-width: 100%; /* Prevents images from being too large */
  height: 200px; /* Ensures all images have the same height */
  object-fit: contain; /* Keeps aspect ratio and fits inside the section */
  margin: 5%;
}

.about-sponsor-button-container{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5%;
}

.about-sponsor-button{
  font-family: var(--primary-font);          /* Use primary font */
  font-size: var(--font-size-h4);                            /* Base font size */
  font-weight: 600;                           /* Semi-bold for impact */
  letter-spacing: 0.5px;                       /* Slight letter spacing */
  text-transform: uppercase;                  /* Uppercase text for emphasis */
  color: var(--dark-navy);                    /* Inverted: dark navy text */
  background-color: var(--white);             /* Inverted: white background */
  border: none;                               /* Remove default border */
  width: 50%;                                 /* 50% of the container width */
  height: 60px;                               /* Fixed height */
  border-radius: 30px;                        /* Pill shape */
  cursor: pointer;                            /* Pointer cursor on hover */
  transition: background-color 0.3s ease, transform 0.2s ease, filter 0.3s ease;
  margin-top: 20px;                           /* Space above the button */
  
  /* Center text horizontally and vertically */
  display: flex;
  align-items: center;
  justify-content: center;
}

.about-sponsor-button:hover {
  filter: brightness(0.9);                    /* Darker on hover */
  transform: translateY(-2px);                /* Slight lift effect on hover */
}


.about-sponsor-button:active {
  transform: translateY(0);                   /* Reset transform on click */
}



.about-footer {
  width: 100%;
  text-align: center;
  background-color: var(--steel-gray); /* Matches the theme */
  color: var(--white);
  padding: 0.5rem;
  font-family: var(--secondary-font);
  font-size: var(--font-size-body);
}

.about-footer-abn {
  font-weight: bold;
}

.about-footer-text,
.about-footer-address {
  line-height: 1.6;
}

.about-footer-link {
  color: var(--electric-cyan);
  text-decoration: none;
  font-weight: bold;
  transition: color 0.3s ease;
}

.about-footer-link:hover {
  color: var(--white);
  text-decoration: underline;
}