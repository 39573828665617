

:root {
  --dark-navy: rgb(15, 22, 39); /* Primary Background */
  --white: #FFFFFF; /* Primary Text */
  --steel-gray: rgb(45, 52, 71); /* Secondary Background */
  --neon-blue: rgb(68, 122, 255); /* Primary Accent (Call-to-Action) */
  --electric-cyan: rgb(72, 209, 204); /* Secondary Accent (Hover Effects) */
  --deep-silver: rgb(190, 190, 190); /* Secondary Text */
  --soft-red: rgb(255, 80, 80); /* Error & Warning Messages */
  --secondary-dark-navy: rgb(28, 40, 67); /* Secondary Background for Navbar/Footer */
}


:root {
  --primary-font: 'Roboto', sans-serif; /* Primary font for body text */
  --secondary-font: 'Poppins', sans-serif; /* Secondary font for headings */
}

/* Font Sizes */
:root {
  --font-size-h1: clamp(2.8vw, 3.2rem, 3.2rem);
  --font-size-h2: clamp(1.8vw, 1.85rem, 1.85rem);
  --font-size-h3: clamp(1.4vw, 1.5rem, 1.5rem);
  --font-size-h4: clamp(1.2vw, 1.2rem, 1.2rem);
  --font-size-h5: clamp(0.9vw, 0.9rem, 0.9rem);
  --font-size-h6: clamp(0.9vw, 1rem, 1rem);
  --font-size-body: clamp(1vw, 1.1rem, 1.1rem);
  --font-size-small: clamp(0.85vw, 0.875rem, 0.875rem);
}




.animate-on-scroll {
  opacity: 0;
  transform: translateY(100px);
  transition: opacity 1s ease-out, transform 1s ease-out;
}

.animate-on-scroll.visible {
  opacity: 1;
  transform: translateY(0);
}




body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  background-image: url('../images/qutrc_logo.png'); /* Replace with your image URL */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100vh;
  position: relative;

  font-size: 1rem !important; /* Base font size */

}

/* Add an overlay to lighten the image */
body::before {
  content: "";
  position: fixed; /* Ensures it covers everything */
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(255, 255, 255, 0.9); /* White overlay with 80% opacity */
  z-index: -1; /* Keeps it behind content */
}



.header-logo{
  position: absolute;
  padding: 0.6rem;

  width: 100%;
  height: 10%;
  top: 0;
  left: 0;
  transform: translateX(-45%);
}

.header-row {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  transform: translateX(30%);
  padding: 1rem;
}

.block {
  width: 100%;
  padding-left: 10%;
}

#footer-div {
  padding-bottom: 65px;    /* Footer height */
}




@media screen and (min-width: 600px) {
  .home-container{
    width: 100%;
  }
}

@media screen and (min-width: 1200px) {
  .home-container{
    width: 100%;
  }
}

.home-image{
  z-index: 6;
  width: 100%;
}

.gallery-div {
  display: flex;
  justify-content: center;
}

.gallery-container {
  width: 80%;
  max-width: 1000px; /* Adjust max-width as needed */
  margin-top: 20px; /* Example margin */
  border: 1px solid black; /* Frame border */
  border-radius: 5px; /* Rounded corners */
  overflow: hidden; /* Ensure images stay within frame */
}




  /* contact us */
  #contact-form {
    margin: auto;
    width: 95%;
  }

.text-width{
  width: 90%;
  margin: 0.5rem;
}
.text-width-space{
  width: 90%;
  margin-bottom: 7%;
}

.styled-button {
  appearance: none;
  background-color: #112236;
  border: 1px solid rgba(27,31,35,.15);
  border-radius: 6px;
  box-shadow: rgba(27,31,35,.1) 0 1px 0;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-family: -apple-system,system-ui,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji";
  font-size: 20px;
  font-weight: 600;
  line-height: 20px;
  padding: 12px 20px;
  position: relative;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
}

.pretty-link {
  display: inline-block;
  font-style: italic;
  margin-right: 5px;
  margin-bottom: 5px;
  text-decoration: none;
  font-size: 16px;
}

.pretty-link:hover {
  text-decoration: underline;
}

.justify {
  text-align: justify;
}

.left {
  text-align: left;
}

.centre {
  text-align: centre;
}

.page-container {
  width: 90%;
  margin: auto;
}

/* (480px, 768px, 1024px, and 1280px). */
@media screen and (min-width: 480px) {
  .page-container {
    width: 75%;
  }
}

@media screen and (min-width: 1024px) {
  .page-container {
    width: 60%;
  }
}

.img {
  max-width: 100%;
  height: auto;
}


@media screen and (min-width: 421px) {
  #contact-form {
    width: 400px;
  }
}


input, textarea {
  width: 100%;
  margin: 5px 0;
  box-sizing: border-box;
  font-size: large;
}

label {
  float: left;
}

.form-group {
  margin-bottom: 5px;
}

.submit-btn {
  width: 100%;
  padding: 12px 20px;
  float: right;
  border: none;
  border-radius: 4px;
  color: white;
  cursor: pointer;
  background-color: #01122b;
}

.dot-point-margin{
  margin-bottom: 0.5%;
  margin-top: 0.5%;
}
