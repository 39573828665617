

.home-container {
  display: flex;
  height: 100vh;
  position: relative;
}

/* Text Section */
.home-text-section {
  flex: 0.3;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2rem;
  background-color: var(--steel-gray);
  position: relative;
  z-index: 2; /* Keep text on top */
}

/* Image Section container with the background color set to steel-gray */
.home-image-section {
  flex: 0.7;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--steel-gray); /* This background will show through the clipped area */
  position: relative;
  overflow: hidden; /* Ensures overflow is hidden */
}

/* The image itself is clipped to create the diagonal cut */
.home-image-section img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  /* Apply the clip-path to the image so that a diagonal portion is removed,
     revealing the container's steel-gray background */
  clip-path: polygon(10% 0%, 100% 0%, 100% 100%, 0% 100%);
}

/* Responsive Fix */
@media (max-width: 768px) {
  .home-container {
    flex-direction: column;
  }

  /* Remove the diagonal cut on small screens */
  .home-image-section img {
    clip-path: none;
  }
}

/* Headings */
.home-container h1 {
  font-size: var(--font-size-h1);      /* Main Section Heading Size */
  font-family: var(--primary-font);     /* Primary Font Family */
  font-weight: 700;                     /* Bold for impact */
  color: var(--white);                  /* White text color */
  margin-bottom: 25px;                  /* Spacing below the heading */
  letter-spacing: 1px;                  /* Slight spacing between letters */
  text-transform: uppercase;            /* Uppercase letters for a strong presence */
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3); /* Subtle shadow for depth */
}

.home-container h3 {
  font-size: var(--font-size-h3);      /* Subheading Size */
  font-family: var(--primary-font);     /* Primary Font Family */
  font-weight: 500;                     /* Medium weight for subheadings */
  color: var(--white);                  /* White text color */
  margin-bottom: 15px;                  /* Spacing below the subheading */
  letter-spacing: 0.5px;                /* Subtle letter spacing */
  text-transform: capitalize;          /* Capitalizes the first letter of each word */
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2); /* Light shadow for added dimension */
}

/* Button Styles */
.home-container button {
  font-family: var(--primary-font);          /* Use primary font */
  font-size: var(--font-size-h4);                            /* Base font size */
  font-weight: 600;                           /* Semi-bold for impact */
  letter-spacing: 0.5px;                       /* Slight letter spacing */
  text-transform: uppercase;                  /* Uppercase text for emphasis */
  color: var(--dark-navy);                    /* Inverted: dark navy text */
  background-color: var(--white);             /* Inverted: white background */
  border: none;                               /* Remove default border */
  width: 50%;                                 /* 50% of the container width */
  height: 60px;                               /* Fixed height */
  border-radius: 30px;                        /* Pill shape */
  cursor: pointer;                            /* Pointer cursor on hover */
  transition: background-color 0.3s ease, transform 0.2s ease, filter 0.3s ease;
  margin-top: 20px;                           /* Space above the button */
  
  /* Center text horizontally and vertically */
  display: flex;
  align-items: center;
  justify-content: center;
}

.home-container button:hover {
  filter: brightness(0.9);                    /* Darker on hover */
  transform: translateY(-2px);                /* Slight lift effect on hover */
}


.home-container button:active {
  transform: translateY(0);                   /* Reset transform on click */
}