
/* Page Styling */
.developers-page {
    color: black;
    font-family: var(--primary-font);
    text-align: center;
    padding: 2rem;
  }
  
  /* Header */
  .developers-page h1 {
    font-family: var(--secondary-font);
    font-size: var(--font-size-h1);
    margin-bottom: 2rem;
  }
  
  /* Headshot Container */

  .headshot-container-dev {
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 3rem;
    padding: 0;
  }

  /* Developer Cards */
  .developer {
    border-radius: 10px;
    padding: 1.5rem;
    width: 250px;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 1);
    transition: transform 0.3s ease-in-out;
  }
  
  .developer:hover {
    transform: scale(1.05);
  }
  
  /* Developer Links */
  .developer-links {
    margin-top: 1rem;
    display: flex;
    gap: 1rem;
  }
  
  .developer-links a {
    text-decoration: none;
    transition: transform 0.2s ease-in-out;
  }
  
  .developer-links a:hover {
    transform: scale(1.1);
    color: var(--electric-cyan);
  }