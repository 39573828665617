.team-page, .developers-page {
    margin: auto;
    width: 80%;
    padding-bottom: 100px;
  }
  
.team-page h1{
    font-style: var(--primary-font);
    font-size: var(--font-size-h1);
    text-align: center;
}

  .headshot-container {
    margin: 0 auto;
    display: grid;
    column-gap: 2.5rem;
    row-gap: 1.5rem;
    list-style-type: none;
    position: relative; /* Ensure the container is positioned */
  }
  
  @media (min-width: 425px) {
    .headshot-container { grid-template-columns: repeat(2, 1fr); }
  
    .team-page, .developers-page {
      width: 75%;
    }
  
    .headshot-container {
      row-gap: 3rem;
    }
  }
  
  @media (min-width: 900px) {
    .headshot-container { grid-template-columns: repeat(3, 1fr); }
  }
  
  @media (min-width: 1440px) {
    .headshot-container {grid-template-columns: repeat(4, 1fr); }
  }
  
  .headshot {
    width: 100%;
    height: auto;
  }
  
  .headshot-image-border {
    background-color: #112236;
    border-radius: 50%;
    display: flex;
    padding: 2%;
  }
  
  .headshot-image {
    border-radius: 50%; 
    margin: 0;
    padding: 0;
    object-fit: cover;
    width: 100%;
    height: 100%;
    aspect-ratio: 1/1;
  }
  
  .name-badge {
    margin: 5px 0 0 0;
  }
  
  .name-badge h2 {
    font-style: var(--primary-font);
    font-size: var(--font-size-h3);
    margin: 0;
    text-align: center;
  }
  
  .name-badge h3 {
    font-style: var(--primary-font);
    font-size: var(--font-size-h4);
    font-weight: 100;
    white-space: pre-wrap;
    text-align: center;
    margin: 0;
  }
  



  .team-container {
    position: relative;
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1%;
  }
  
  .team-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3); /* Dark overlay for contrast */
    border-bottom: 5px solid var(--secondary-dark-navy); /* Added navy blue border */
  }
  
  .team-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    text-align: center;
    z-index: 2;
    width: 90%; /* Ensure text is readable and not cut off */
    padding: 10px;
  }
  
  .team-text h1 {
    font-size: clamp(2rem, 5vw, 5rem); /* Scales based on viewport width */
    font-family: var(--primary-font);

    letter-spacing: 2px;
    margin: 0;
  }

  .invisible-button {
    opacity: 0;
    position: absolute;
    top: 42%;
    left: 42%;
    width: 3%;
    height: 3%;
    cursor: pointer;
    z-index: 1;
  }